
export default {
    props: {
        number: {
            type: [String, Number],
            default: 1,
        },
        imageWidth: {
            type: [Number, String],
            default: 100,
        },
    },
    computed: {
        count() {
            const numberString = this.number.toString().padStart(6, '0')
            return numberString.split('')
        },
    },
    methods: {
        isSmallScreen() {
            return this.imageWidth < 180
        },
    },
}
