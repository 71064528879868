
import { mapGetters } from 'vuex'
export default {
    props: {
        imageWidth: {
            type: [Number, String],
            default: 280,
        },
    },
    computed: {
        ...mapGetters({
            lotteryWinner: 'cms/lotteryWinner',
        }),
    },
    methods: {
        isSmallScreen() {
            return this.imageWidth < 300
        },
    },
}
