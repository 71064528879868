
import { mapGetters } from 'vuex'
export default {
    data() {
        return {
            slider: 0,
            sliderItemHeight: 60,
            sliders: [1, 2, 3, 4],
            sliderWidth: 733,
            sliderHeight: 250,
        }
    },
    computed: {
        imageWidth() {
            return Math.floor((this.sliderItemHeight * 1320) / 450) - 48
        },
        ...mapGetters({
            desktopSlider: 'cms/desktopSlider',
        }),
        slideLength() {
            if (this.desktopSlider?.items.length === 1) return 12
            if (this.desktopSlider?.items.length === 2) return 6
            if (this.desktopSlider?.items.length === 3) return 4
            if (this.desktopSlider?.items.length === 4) return 3
            return 12
        },
    },
    mounted() {},
    methods: {
        getSliderImageWidth() {
            const client = process.client
            if (client) {
                // Get slider width when size change
                const val = this.$refs?.refSlider?.clientWidth
                this.sliderWidth = val
                if (val) {
                    // Calculate slider height on ratio 1320:450
                    const height = Math.floor((val * 450) / 1320)
                    this.sliderHeight = height
                    // Display only 4 card if more than that should scrool down
                    this.sliderItemHeight = Math.ceil(height / 4) - 10
                }
            } else {
                setTimeout(() => {
                    this.getSliderImageWidth()
                }, 1000)
            }
        },
    },
}
